.WAButton {
    &__Wrapper {
        z-index: 9999;
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        // max-height: 48px;
    }
    &__Link {
        display: flex;
        align-items: center;
    }
    &__Text {
        background-color: #FFF;
        padding: 0.8rem 3rem 0.8rem 2rem;
        border-radius: 2rem;
        border: 4px #48cc5d solid;
        margin-right: -2rem;
        font-size: 18px;
        color: rgb(35, 80, 66);
        font-family: 'Karla', sans-serif;
    }
    &__Image {
        max-height: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .WAButton {
        &__Wrapper {
            width: 100vw;
            display: flex;
            justify-content: center;
            margin-right: -0.5rem;
            // bottom: 0.5rem;
            // right: 0.5rem;
            & + div {
                padding-bottom: 8rem !important;
            }
        }
    }
}