@import '../node_modules/semantic-ui-css/semantic.min.css';
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Montserrat&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-content: stretch;
}

.MerriweatherBox {
  // max-height: 90vh;
  color: #FFF;
  h1,
  h2,
  h3,
  h4, 
  h5,
  h6 {
    font-family: 'Karla', sans-serif !important;
  }
  .field {
    label {
      color: #FFF !important;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ui.dimmer{
  background-color: rgba(0,0,0,0.90);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.HeaderLogo{
  margin-bottom: 0px !important;
  text-align: center !important;
  img{
    height: 100px;
  }
}

.TopMenu{
  margin-top: 0px !important;
  border-radius: 0px !important;
  border-bottom: 4px #1971b9 solid !important;
  font-family: 'Montserrat' !important;
  font-size: 24px !important;
}
.ui.buttons .or:before {
  background-color: #111 !important;
  color: #FFF;
}

body {
  background: url('./Assets/PiT_Login.jpeg');
    background-position: center center;
    background-size: cover;
    // background sticky
    background-attachment: fixed;
}

.MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #BEAEC399;
  &.ThanksYou {
    min-height: 100%;
  }
  padding: 3rem 0rem;
  &__Logo {
    width: 12vw;
    max-width: 64px;
    margin-bottom: 3rem;
  }
  .ui.form {
    max-width: 980px;
    border-radius: 6px;
    background: #592063;
    padding: 0rem 6rem 3rem 6rem;
  }


  // overflow: hidden !important;
  &--Library{
    background: #FFF;
    padding: 0px 24px;
    padding-top: 32px;
    text-align: left;
    margin-bottom: 32px;
    .list{
      margin-top: 32px;
    }
  }
  iframe {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 205.5px);
    min-height: calc(100vh - 205.5px);
  }
  
  .six.wide.column{
    padding-right: 64px;
    video {
      // object-fit: cover;
    }
  }
  .CourseSelectorContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    .ui.card{
      margin: 48px 24px;
      width: 25%;
      .image
      {
        background: #f2f7fa;
        img {
          max-height: 185px;
          object-fit: contain;
        }
      }
    }
  }
  
  .Selector{
    background: rgba(0,0,0,0.0) !important;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 0px !important;
    margin: 0px !important;
    *:not(i) {
      font-family: 'Montserrat' !important;
      font-size: 18px;
    }
    i{
      font-size: 60px !important;
      margin-bottom: 24px !important;
    }
    button{
      margin-top: 13px;
    }
    .ui.segment{
      & + .ui.segment {
        margin-top: 0px;
        // margin: 48px 16px;
      }
      // margin: 48px 16px;
      max-height: 64px;
      background: rgba(0,0,0,0.0) !important;
    }
  }
  .Bienvenida{
    font-family: 'Montserrat';
    font-size: 18px;
    text-align: left;
    line-height: 28px;
    background: none !important;
    text-shadow: #000 1px 1px 10px;
    .BigText{
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 12px;
      float: left;
      width: 100%;
      text-shadow: #000 1px 1px 10px;
    }
  }
  .LoginBox{
    font-family: 'Montserrat';
    font-size: 22px;
    width: 45vw;
    margin-left: 5vw;
    min-width: 500px;
    background: rgba(0,0,0,0.65) !important;
    text-align: left;
    line-height: 26px;
    .ui.form{
      margin-top: 16px;
      .field{
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
    .FormLogin{
      display: flex !important;
    }
  }
}
.row.HeaderRow{
  .column{
    max-width: 100vw;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}
.MerriweatherBox{
  // max-height: 90vh;
  *:not(i),&:not(i){
    font-family: 'Montserrat', sans-serif !important;
    // font-weight: 300 !important;
    font-size: 17px;
  }
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  .column{
    width: 70vw;
    max-width: 70vw;
    &:first-child{
      padding-top: 48px;
    }
    .row{
      text-align: left;
      &-html{
        h1, h2, h3, h4, h5, h6 {
          font-family: 'Karla', sans-serif !important;
          font-weight: bold;
          border-left: 8px solid #CCC;
          padding-left: 8px;
          margin-bottom: 16px;
        }
        h1{ font-size: 28px; }
        h3{ font-size: 23px; }
        h6{ font-size: 20px; }
        
        margin-bottom: 32px;
      }
      &-line,&-multiline,&-calendar{
        margin-bottom: 16px;
        input, textarea{
          background: #FFF;
          border-color: #8A6C94;
        }
      }
      &-sendButton{
        display: flex;
        justify-content: space-between;
        margin-top: 48px;
        .ui.buttons .or:before{
          background-color: #FFF !important;
          color: #111 !important;
        }
      }
    }
    
  }
}

.row-matrix{
  .react-grid-Row{
    .react-grid-Cell:first-child{
      background: #f9f9f9 !important;
      .react-grid-Cell__value div{
        font-weight: bold !important;
      }
    }
  }
  .react-grid-HeaderCell{
    * {
      font-weight: bold !important;
    }
  }
  margin-bottom: 24px;
}

.row-radio{
  margin-bottom: 24px;
}

.row-likert{
  margin-bottom: 24px;
}


.row-conditional{
  margin-bottom: 24px;
  .row-html{
    margin-left: 12px;
    font-size: 80%;
    border-left: 4px #fc0 solid;
    padding-left: 24px;
  }
  .row.row-multiline {
    label {
      margin-left: 12px;
      font-size: 100%;
      padding-left: 24px;
      font-weight: normal;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .negative label {
      border-left: 4px #BEAEC3 solid;
    }
    .positive label {
      border-left: 4px #235042 solid;
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  /* CSS */
  .row.MerriweatherBox{
    .column{
      max-width: 95vw !important;
    }
  }  
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
  .row.MerriweatherBox{
    .column{
      max-width: 85vw !important;
    }
  }  
}

.react-grid-Row {
  & .react-grid-Cell__value {
    white-space: break-spaces !important;
  }
}

.ContentType0{
  display: flex;
  align-items: center;
  flex-direction: column;
  .ContainerContentType0{
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    & > .ui.card.tada{
      // flex-grow: unset;
      // width: calc(25% - 64px);
      flex-basis: 27% !important;
      align-items: center;
      margin-left: 32px;
      &:nth-child(3n){
        margin-left: 48px !important;
        margin-right: 16px !important;
      }
      // margin: 32px;
      &:last-child{
        /* flex-basis: 27%;
        margin-left: 37%;
        flex-grow: unset; */
        margin-bottom: 32px;
        image{
          // height: 100px;
        }
      }
    }
  }
}


.disabled input{
  color: #000 !important;
  opacity: 1 !important;
}

.ui.left.pointing.basic.label.forceBlack {
  color: #000 !important;
}

.PiT {
  &__LoginScreen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    &__Left {
      &__Wrapper {
        display: flex;
        flex-direction: column;
        width: 35vw;
        height: 100%;
      }
      flex-grow: 1;
      background: #592063;
      max-width: 50vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0rem 3rem;
      // justify-content: center;
      h2 {
        color: #FFF;
        font-family: 'Karla', sans-serif;
        margin: 0rem 1rem;
        text-align: left;
        font-weight: 300;
        font-size: 2.75rem;
      }
      p {
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        margin: 1.5rem 1rem;
        margin-bottom: 0.5rem;
        text-align: left;
        font-size: 1.5rem;
      }
      &__Logo {
        flex-grow: 3;
        text-align: left;
        margin-left: 0.25rem;
        // justify-self: flex-start;
        // justify-content: flex-start;
        img {
          margin-top: 3rem;
          max-width: 16vw;
        }
      }
      &__LoginBox {
        flex-grow: 5;
        padding: 1rem;
        width: 100%;
        .segment {
          background: #BEAEC3;
          padding: 24px;
        }
      }
    }
    &__Right {
      flex-grow: 1;
      background: url('./Assets/PiT_Login.jpeg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      &__Content {
        background: #BEAEC399;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Colors
.ui.teal {
  background-color: #689287 !important;
}

a {
  color: #689287;
  font-weight: bold;
  &:hover {
    color: #235042;
  }
}

.row-upload {
  span {
    color: #689287;
      font-weight: bold;
      cursor: pointer;
    
      &:hover {
        color: #235042;
      }
  }
}

.RecordWebcam {
  &__Video {
    width: 100%;
    transform: scaleX(-1);
    &__Preview{
      &_stPREVIEW{
        width: 100%;
      }
      &_stINIT,
      &_stOPEN,
      &_stRECORDING,
      &_stCLOSED,
      &_stERROR {
        display: none;
      }
    }
  }
  &__Timer {
    position: absolute !important;
    z-index: 999;
    top: 24px;
    right: 16px;
  }
  &__Options {
    position: absolute !important;
    top: 0px;
    z-index: 1000;
    width: 98%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 6rem !important;
    &__Started {
      opacity: 0.65; 
    }
    .content small {
      font-weight: 100;
    }
  }
}

@media only screen and (max-width: 768px) {
  .RecordWebcam {
    &__Segment {
      min-height: 264px;
    }
    &__Options {
      padding: 0rem 0.25rem !important;
      width: 90%;
      .ui.buttons {
        flex-direction: column;
        gap: 8px;
        .or {
          display: none;
        }
      }
    }
  }
  .MainContent {
    padding-bottom: 0px !important;
    .ui.form {
      padding: 0.25rem;
      width: 100%;
      border-radius: 0px;
    }
  }
  .MerriweatherBox {
    padding: 0px !important;
    .row-sendButton {
      display: flex;
      flex-direction: column;
      margin: 48px 0px;
      gap: 16px;
    }
    .row-upload {
      small {
        padding-left: 0px !important;
      }
    }
  }
  .PiT {
    &__LoginScreen {
      flex-direction: column-reverse;
      &__Left {
        max-width: 100vw;
        padding: 0.5rem;
        &__Wrapper {
          width: 100%;
          height: 100%;
        }
        &__Logo {
          text-align: left;
          img {
            max-width: 75vw;
          }
        }
        &__LoginBox {
          width: 100%;
          padding: 1rem;
          .segment {
            padding: 0rem;
            div.ui.big.action.left.icon.input {
              display: flex;
              flex-direction: column;
            }
            i.address.card.outline.icon {
              align-self: flex-start;
              height: 48px;
            }
          }
        }
      }
      &__Right {
        display: none;
      }
    }
  }
}
